import { Site } from "@libry-content/types";
import { isJestTest, platformUrl } from "./platform";

const addDefaulLanguage = (site: Pick<Site, "defaultLanguage">, skipLanguage: boolean, url: string): string =>
  skipLanguage || !site.defaultLanguage ? url : `${url}/${site.defaultLanguage}`;

export const platformRootUrl = new URL(`${platformUrl.protocol}//${platformUrl.host}`);

export const splashSiteUrl = platformRootUrl;

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (
  site: Pick<Site, "domain" | "defaultLanguage" | "_type">,
  options = { skipLanguage: false }
): string => {
  const { domain } = site;
  const [platformDomain, port] = platformUrl.host.split(":"); // Split on ":" for localhost
  const isLocalhost = platformDomain === "localhost";

  if (isLocalhost && !isJestTest) {
    const isCustomDomain = !domain?.endsWith(platformDomain!); // If the domain does not end with the platform domain (e.g. froland.bibliotek.io), it's a custom domain (e.g. tnb.no)
    // Custom domains are not supported on localhost. Using a readable url as error message as it should be easy to spot in logs
    if (isCustomDomain) return `/custom-domain/${domain}/not-supported-on-localhost`;
    return addDefaulLanguage(site, options.skipLanguage, `http://${domain}:${port}`);
  }

  return addDefaulLanguage(site, options.skipLanguage, `https://${domain}`);
};

export const adminSiteUrl = new URL(`${platformUrl.protocol}//admin.${platformUrl.host}`);

export const isAdminSiteDomain = (domain?: string): boolean => domain === adminSiteUrl.host.split(":")[0]; // Split on ":" for localhost
