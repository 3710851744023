import { EventType } from "@libry-content/types";
import groq from "groq";
import { z } from "zod";
import {
  ResolvedLibrarySummary,
  resolveLibrarySummary,
} from "../../../../../apps/frontend/src/components/library/sanityQuery";
import { TargetAudienceCode } from "../../targetAudiences";
import { ResolvedEventSummary, resolveEventSummaryGroqProjection } from "./resolveEvent";
import { finishedEventsGroqQuery, unFinishedEventsGroqQuery } from "./eventFilters";

export const eventsUrlStateSchema = z
  .object({
    kategori: z.array(z.string()).optional(),
    bibliotek: z.array(z.string()).optional(),
    malgruppe: z.array(z.string()).optional(),
    pagination: z.number().optional(),
  })
  .optional();

export type EventsUrlState = z.infer<typeof eventsUrlStateSchema>;

const eventsUrlFilters = groq` [
      (!defined($kategori) || length($kategori) == 0 || eventType->label[$lang] in $kategori) &&
      (!defined($bibliotek) || length($bibliotek) == 0 || library->slug in $bibliotek) &&
      (!defined($malgruppe) || length($malgruppe) == 0 || array::intersects(targetAudiences, $malgruppe))
  ]`;

export type FilteredEventsWithRelevantFacets = {
  events: ResolvedEventSummary[];
  facets: RelevantEventFacets;
};

export type RelevantEventFacets = {
  libraries: ResolvedLibrarySummary[];
  eventTypes: EventType[];
  targetAudiences: TargetAudienceCode[];
};

export type FinishedOrUnfinishedEvents = "finished" | "unfinished";
export const getFilteredEventsWithRelevantFacets = (finishedOrUnfinishedEvents: FinishedOrUnfinishedEvents) => groq`{ 
    "filteredEvents": ${
      finishedOrUnfinishedEvents === "unfinished" ? unFinishedEventsGroqQuery : finishedEventsGroqQuery
    } ${eventsUrlFilters} ${resolveEventSummaryGroqProjection},
  }
  {
    "events": @.filteredEvents [$start..$end],
    "facets": {
      "libraries": UNSCOPED[_type == "library" && _id in array::unique(^.filteredEvents[].library._id)]{${resolveLibrarySummary}},
      "eventTypes": UNSCOPED[_type == "eventType" && _id in array::unique(^.filteredEvents[].eventType._id)],
      "targetAudiences": array::unique(@.filteredEvents[].targetAudiences[]),
    }
  }
`;
