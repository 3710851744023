const commaSeparatedNameRegex = /^([^,]+),([^,]+)$/;

/**
 * Naive attempt to format name as "lastnames, firstname" -> "firstname lastnames"
 */
export const formatNameInformal = (name: string): string =>
  name?.match(commaSeparatedNameRegex) ? name.replace(commaSeparatedNameRegex, "$2 $1").trim() : name;

const hasToString = (item: unknown): item is { toString: () => string } =>
  typeof item === "object" && typeof item?.["toString"] === "function";

export const getErrorMessage = (error: unknown): string => {
  if (!error) return "";
  if (error instanceof Error) return error.message;
  if (typeof error === "string") return error;
  if (hasToString(error)) return error.toString();
  return JSON.stringify(error);
};

// Source: https://stackoverflow.com/a/67243723/1540547
export const camelToKebabCase = (str: string): string =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    (capitalLetter, isNotBeginning) => (isNotBeginning ? "-" : "") + capitalLetter.toLowerCase()
  );

export const truncateString = (text: string | undefined, maxLength: number): string => {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength - 3)}…`;
};

export const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);
