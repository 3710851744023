import groq from "groq";
import { resolveRepeatedEvents } from "./repeatedEvents";

const notRepeated = groq`(!repeated || !defined(repeated))`;

// "null" is interpeded as infinity. For example, if from is "null" and to is "now()" we get all past events
export type ScheduleParams = { from: string | null; to: string | null };

const repeatedEventOccurrenceScheduleFilter = ({ from, to }: ScheduleParams) => groq`
  (!defined(${from}) || @.eventSchedule.endsAt >= ${from}) &&
  (!defined(${to}) || @.eventSchedule.endsAt <= ${to})
`;

const scheduleFilter = ({ from, to }: ScheduleParams) => groq`
(
  (
    ${notRepeated} && 
    (!defined(${from}) || eventSchedule.endsAt >= ${from}) &&
    (!defined(${to}) || eventSchedule.endsAt <= ${to}) 
  ) ||
  (
    repeated && 
    count(repeatedEventOccurrences[${repeatedEventOccurrenceScheduleFilter({ from, to })}]) > 0
  )
)`;

export const eventFilters = Object.freeze({
  scheduleFilter,
  repeatedEventOccurrenceScheduleFilter,
  repeatedEventOccurrenceNotFinished: repeatedEventOccurrenceScheduleFilter({
    from: "now()",
    to: null,
  }),
  repeatedEventOccurrenceFinished: repeatedEventOccurrenceScheduleFilter({
    from: null,
    to: "now()",
  }),
  finished: scheduleFilter({ from: null, to: "now()" }),
  notFinished: scheduleFilter({ from: "now()", to: null }),
  lacksDates: groq`(
    (${notRepeated} && !defined(eventSchedule.startsAt)) ||
    (repeated && !defined(repeatedEventOccurrences[0].eventSchedule.startsAt))
  )`,
});

export const eventsGroqQuery = (scheduleParams: ScheduleParams) => groq`
*[_type == 'event' && ${eventFilters.scheduleFilter(scheduleParams)}]
  ${resolveRepeatedEvents(repeatedEventOccurrenceScheduleFilter(scheduleParams))}
`;

export const unFinishedEventsGroqQuery = groq`${eventsGroqQuery({
  from: "now()",
  to: null,
})} | order(eventSchedule.startsAt asc)`;

export const finishedEventsGroqQuery = groq`${eventsGroqQuery({
  from: null,
  to: "now()",
})} | order(eventSchedule.startsAt desc)`;
